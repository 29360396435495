#registerTabs{
    border-radius: 0px !important;
    border: none !important;
}
#customGenInfo{
    padding-left: 0px;
}
input[type=password]{

    background-color: #eef0f8;
}

@media only screen and (max-width: 530px){
    .customGrid{
        flex-direction: column;
    }
    #tabsInfo{
        display: none;
    }
    #main{
        padding-top: 0px !important;
    }
    
    .MuiSelect-icon{
        height: 100% !important;
        padding: 12px 2px !important;
        top: calc(50% - 27px) !important;
        width: 11% !important;
    }
    .register_dropdDownWidth__2LsfJ{
        margin-right: 30px !important;
    }
}

/* General Information */
#my-input-id:hover {
border: 1px solid;
}
#my-input-id:focus {
    box-shadow: none;
    border: 2px solid;
}
