.ui-row-background-green {
  /* background-color: #50ae54 !important; */
  /* background-color: #3d8a10 !important; */
  background-color: #f67d7d !important;
  font-weight: bold !important;
  color: white !important;
  -webkit-font-smoothing: antialiased !important;
}
.ui-row-background-green:hover {
  color: white !important;
  border-color: #aef2b0 !important;
  font-style: italic;
  -webkit-font-smoothing: antialiased !important;
}

.ui-row-background-yellow {
  /* background-color: #f0b52c !important; */
  /* background-color: #f7a344 !important; */
  background-color: #c165e0 !important;
  font-weight: bold !important;
  color: white !important;
  -webkit-font-smoothing: antialiased !important;
}
.ui-row-background-yellow:hover {
  color: white !important;
  border-color: #fffd8c !important;
  font-style: italic;
  -webkit-font-smoothing: antialiased !important;
}

.ui-row-background-red {
  background-color: red !important;
  font-weight: bold !important;
  color: white !important;
}
.ui-row-background-red:hover {
  color: white !important;
  border-color: #ff7c77 !important;
  font-style: italic;
}

.ui-rate-detail-inner-row-background-green {
  /* background-color: #50ae54 !important; */
  /* background-color: #adde91 !important; */
  background-color: #f67d7d !important;
  font-weight: bold !important;
  color: white !important;
  -webkit-font-smoothing: antialiased !important;
  cursor: auto;
  font-size: 15px;
}

.ui-rate-detail-inner-row-background-yellow {
  /* background-color: #f0b52c !important; */
  /* background-color: #ffd8aa !important; */
  background-color: #c165e0 !important;
  font-weight: bold !important;
  color: white !important;
  -webkit-font-smoothing: antialiased !important;
  cursor: auto;
  font-size: 15px;
}

.ui-rate-detail-inner-row-background-red {
  background-color: red !important;
  font-weight: bold !important;
  color: white !important;
  cursor: auto;
  font-size: 15px;
}

.no-padding {
  padding: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999999;
  /*
  font-size: 1.3em;
  font-weight: bold; */
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
  /*font-size: 1.3em;
  font-weight: bold; */
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
  /*font-size: 1.3em;
  font-weight: bold; */
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999999;
  /*font-size: 1.3em;
  font-weight: bold; */
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.ui-datepicker .uidatepicker-title {
  line-height: unset;
}

.smallNinjaLogoBackground {
  background-image: url("assets/ninja/layout/images/NinjaRater-Logo-iPhoneRetina.png");
  background-repeat: no-repeat;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  background-color: white;
  height: 45px;
  padding: 10px 12px;
  border-radius: 23px;
  border: 1px solid silver;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #b00020;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.wordwrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
body .ui-widget-header {
  color: #fff !important;
  /* background: #8426b0 !important;
  background: -webkit-linear-gradient(
    135deg,
    #8426b0 3%,
    #bd0283 47%,
    #ec4b3c 98%
  ) !important; */
  background: linear-gradient(
    135deg,
    rgb(130, 35, 172) 0%,
    rgb(187, 5, 129) 53%,
    rgb(233, 77, 66) 100%
  ) !important;
  border: 0 solid #8426b0 !important;
}
.ui-datatable-emptymessage {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.commonButtonClass {
  color: #fff !important;
  /* background: #8426b0 !important; */
  /* background: -webkit-linear-gradient(
    135deg,
    #8426b0 3%,
    #bd0283 47%,
    #ec4b3c 98%
  ) !important; */
  background: linear-gradient(
    135deg,
    rgb(130, 35, 172) 0%,
    rgb(187, 5, 129) 53%,
    rgb(233, 77, 66) 100%
  ) !important;
  border: 0 solid #8426b0 !important;
  font-size: 15px !important;
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: bold !important;
  /* height: 50px !important; */
}
.previous_head {
  padding: 10px 10px;
}
body .ui-widget {
  font-family: "Helvetica", "Arial", "sans-serif" !important;
  font-size: 15px !important;
}
label.required:after {
  content: " *";
  color: red;
}
#DIV_RATES_PAGE_ID {
  /* font-family: "Helvetica", "Arial", sans-serif; */
}
#DIV_RATES_PAGE_ID #main {
  background-color: #f8f9fa !important;
}
#rateQuote .ui-inputtext,
#rateQuote_one .ui-inputtext,
#rateQuote .payroll {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
}
#rateQuote_one .ui-calendar {
  width: 100%;
}
#rateQuote ul {
  list-style: none;
  margin-left: 0;
}
#rateQuote .ui-autocomplete-list-item {
  margin: 0;
  padding: 0.186em 0.313em;
}
#rateQuote {
  border: 1px solid #efefef;
  border-left: 8px solid #efefef;
  border-radius: 0.3em;
  padding-top: 10px;
  margin: 0px;
  margin-bottom: 10px;
}
#rateQuote_one {
  border: 1px solid #f8f9fa;
  border-left: 8px solid #f8f9fa;
  border-radius: 0.3em;
  padding-top: 10px;
  margin: 0px;
  margin-bottom: 10px;
}
#rateQuote_one #ID_POLICY_DATE_INPUT input {
  cursor: pointer;
}
#rateQuote .ui-autocomplete,
#rateQuote .class-code,
#rateQuote_one #ex-mod-input {
  margin-bottom: 0;
}
.signInBtn {
  margin-right: 0px !important;
}

table.add-user tr th,
table.table tr td {
  border-color: #e9e9e9;
}
table.add-user th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}
table.add-user th:last-child {
  width: 100px;
}
table.add-user td a {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  min-width: 24px;
}
table.add-user td a.add {
  color: #27c46b;
}
table.add-user td a.edit {
  color: #ffc107;
}
table.add-user td a.delete {
  color: #e34724;
}
table.add-user td i {
  font-size: 19px;
}
table.add-user td a.add i {
  font-size: 24px;
  margin-right: -1px;
  position: relative;
  top: 3px;
}
table.add-user .form-control {
  height: 32px;
  line-height: 32px;
  box-shadow: none;
  border-radius: 2px;
}
table.add-user .form-control.error {
  border-color: #f50000;
}
table.add-user td .add {
  display: none;
}
/*  */
.ui-row-toggler {
  font-size: 17px !important;
}
.MuiPagination-ul {
  list-style: none !important;
}
.MuiButtonBase-root {
  border-radius: 10px !important;
}
/* Spinner */
#spinnerWrap {
  max-height: 100vh;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: -112px !important;
  left: 0;
}
.isLoaderGen {
  z-index: -1;
  position: relative;
}

/* Carier Table */
.carierTh {
  background-color: #f8f8f8;
  font-weight: 600;
  color: black;
  border-bottom: 2px solid lightgray;
  padding: 12px 0px;
  max-width: 100% !important;
  margin: 0 !important;
}
.carierTr {
  border-bottom: 1px solid lightgray;
  padding: 11px 0px 8px 0px;
  background-color: #f1f1f1;
  max-width: 100% !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  color: black;
}
.carierTr:nth-child(even) {
  background-color: #fefefe;
}
/* forgot Password */
.forgotSection {
  background-color: white;
  text-align: center;
  margin: auto !important;
  width: -webkit-fill-available;
}
.forgotDiv1height {
  height: 400px;
}
.forgotDiv1 {
  background: linear-gradient(
    135deg,
    rgb(130, 35, 172) 0%,
    rgb(187, 5, 129) 53%,
    rgb(233, 77, 66) 100%
  ) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  height: auto;
  height: 365px;
}
.forgotDiv2 {
  text-align: center;
  margin: auto;
}
.forgotDiv1-head,
.forgotDiv2-head {
  font-size: 45px;
  margin-bottom: 15px;
}
.forgotDiv1-desc,
.forgotDiv2-desc {
  font-size: 20px;
}
#forGotEmail {
  width: 80%;
  margin: auto;
  height: 63px;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}
#forGotEmail:focus,
#forGotEmail:focus {
  outline: none !important;
}
@media screen and (max-width: 600px) {
  .forgotSection {
    margin-top: 10px !important;
  }
  .forgotDiv1 {
    background: none !important;
    color: #495057;
    height: auto;
  }
  .forgotDiv1-desc,
  .forgotDiv1-head {
    display: none;
  }
  .forgotDiv2-desc,
  .forgotDiv2-head {
    display: block !important;
  }
  /* sign up or create new account */
  .mkResponsiveDiv {
    width: 350px !important;
    margin: auto !important;
  }
  #registerTabs {
    max-width: 100% !important;
    margin: auto;
  }
  .responsiveTabs {
    margin-left: 0 !important;
  }
  #DIV_2 {
    display: none !important;
  }
  /* Ninjareater App */
  .homeWellcome {
    top: 70px !important;
  }
  .register_banner_buttons__34CuT {
    top: 44% !important;
  }
  .subActTabRes {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  /* User Activity */
  #btnActExprtXlsx {
    margin-left: 0px !important;
  }
}
/* Subscriber */
.modal-dialog {
  max-width: fit-content !important;
}
#fromDate,
#toDate {
  border-radius: 0px !important;
}
#closeIcon {
  font-size: 20px;
}
#closeIcon:hover {
  color: brown;
  cursor: pointer;
}
.alert-dismissible .close{
  font-size: 23px;
}