.ui-widget-header {
    font-size: 24px;
    font-weight: bold;
  }
  #ID_RATES_SUMMARY{
    padding: 0px 105px 0px 105px;
  }
  .fa-sort-asc:before {
    content:none im !important;
  }

  @media screen and (max-width: 530px) {
    #ID_RATES_SUMMARY{
      padding: 0px;
    }
    .responsiveBtn{
      display: flex;
      flex-direction: column;
    }
    .responsiveBtn button{
      margin: 5px;
    }
  }