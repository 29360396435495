.layoutMenu{
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}
.nanoContent{
    top: 56px !important;
}
.positionUnset{
    position: unset !important;
}