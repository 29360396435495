.slider{
  position:relative;

}
.slider_content{
  height: 50vh;
  width: 100%;
  z-index:20;
  position: absolute;
  margin-top: 30px;
}